table::-webkit-scrollbar {
  width: 0.25em;
}  
table::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
}
