body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: "Aeonik";
  src: url("./assets/Aeonik/AeonikTRIAL-Regular.otf");
  font-weight: normal;
}

/* @font-face {
  font-family: "Aeonik";
  src: url("./assets/Aeonik/AeonikTRIAL-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/Aeonik/AeonikTRIAL-Medium.otf");
  font-weight: 600;
} */

@font-face {
  font-family: "Aeonik";
  src: url("./assets/Aeonik/AeonikTRIAL-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/Aeonik/AeonikTRIAL-Bold.otf");
  font-weight: 800;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/Aeonik/AeonikTRIAL-Bold.otf");
  font-weight: 900;
}

@font-face {
  font-family: "DMSans";
  src: url("./assets/DM_Sans/DMSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "DMSans";
  src: url("./assets/DM_Sans/DMSans-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "DMSans";
  src: url("./assets/DM_Sans/DMSans-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "DMSans";
  src: url("./assets/DM_Sans/DMSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "DMSans";
  src: url("./assets/DM_Sans/DMSans-Bold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "DMSans";
  src: url("./assets/DM_Sans/DMSans-Bold.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Roslindale";
  src: url("./assets/Roslindale_Font/Roslindale-TextSemiBold-Testing.woff");
  font-weight: 600;
}

@font-face {
  font-family: "Roslindale";
  src: url("./assets/Roslindale_Font/Roslindale-TextRegular-Testing.otf");
  font-weight: 400;
}




@tailwind base;
@tailwind components;
@tailwind utilities;